<template>
  <TepmplateBlock
    content-class="main-wrap pt-4 m-10"
    mod-class
    title-page="Редактирование"
  >
    <section class="edit">
      <div class="edit__avatar">
        <img src="../../resource/img/edit-ava.svg" alt="user's avatar" />
      </div>
      <a class="edit__password" href="#">Изменить пароль</a>
      <form class="edit__form">
        <label class="edit__form__firstname">Имя <input type="text"/></label>
        <label class="edit__form__lastname">Фамилия <input type="text"/></label>
        <label class="edit__form__born"
          >Дата рождения <input type="date"
        /></label>
        <label class="edit__form__ofice">Офис <input type="text"/></label>
        <label class="edit__form__email">E-mail <input type="text"/></label>
        <label class="edit__form__phone"
          >Телефон НУЖНО ЛИ ДОБАВОЧ? <input type="text"
        /></label>
        <div class="edit__form__btn-group">
          <button class="edit__form__btn-group__back">Назад</button>
          <button class="edit__form__btn-group__save">Сохранить</button>
        </div>
      </form>
    </section>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "@/components/TepmplateBlock";
export default {
  name: "EditUser",
  components: { TepmplateBlock }
};
</script>

<style lang="scss" scoped>
.edit {
  margin: 47px auto 0 auto;
  padding: 17px 65px 53px;
  width: 480px;
  height: 754px;
  left: 400px;
  top: 206px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  &__avatar {
    margin: 0 auto;
    width: 83px;
    height: 83px;
    img {
      width: 100%;
    }
  }
  &__password {
    margin: 12px auto 0 auto;
    text-align: center;
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #3579f6;
  }
  &__form {
    margin-top: 13px;
    label {
      margin-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: #393952;
      input {
        margin-top: 4px;
        padding: 0 20px;
        display: block;
        width: 350px;
        height: 46px;
        background: #f1f1f3;
        border-radius: 4px;
        border: none;
        outline: none;
      }
    }
    &__btn-group {
      margin-top: 34px;
      display: flex;
      justify-content: space-between;
      &__back {
        width: 160px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #2c698c;
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #2c698c;
      }
      &__save {
        width: 160px;
        height: 46px;
        background: #2c698c;
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #ffffff;
        border: none;
      }
    }
  }
}
</style>
